.clip-path-polygon {
  clip-path: polygon(0 83%, 0% 100%, 100% 100%);
}

.clip-path-polygon-bottom {
  clip-path: polygon(0 83%, 0% 100%, 100% 100%);
}

.react-datepicker-wrapper {
  width: 100%;
}